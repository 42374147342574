const assetsLang = {
  en: {
    // General asset management
    assets: "Assets",
    assetsList: "Assets list",
    addAsset: "Add asset",
    editAsset: "Edit asset",
    assetDetails: "Asset details",

    // Asset properties
    assetName: "Asset name",
    assetCode: "Asset code",
    assetType: "Asset type",
    productType: "Product type",
    acquisitionDate: "Acquisition date",
    expiryDate: "Expiry date",
    value: "Value",
    valueProduct: "Product value",
    depreciation: "Depreciation",
    percentDepreciation: "Depreciation percentage",
    observations: "Observations",

    // Asset related entities
    productTypes: "Product types",
    unitMeasurement: "Unit of measurement",
    cooperant: "Cooperant",
    provider: "Provider",

    // Asset statuses
    inactive: "Inactive",
    actives: "Actives",
    activeList: "Active list",
    addActive: "Add active",

    generateQRCode: "Generate QR Code",
    assetVerificationQR: "Asset verification QR Code",
    assetID: "Asset ID",
    scanQRToVerify: "Scan this QR code to verify the asset",
    downloadQR: "Download QR",

    // Asset assignment
    assignAsset: "Assign asset",
    assignments: "Assignments",
    newAssignment: "New assignment",
    deliveryDate: "Delivery date",
    returnDate: "Return date",
    returnAsset: "Return asset",
    currentAssignee: "Current assignee",
    assignmentHistory: "Assignment history",
    noAssignmentsFound: "No assignments found",
    headquarters: "Headquarters",
    user: "User",

    // Asset operations
    printCard: "Print card",
    printResponsibilityCard: "Print responsibility card",
    responsibilityCard: "Responsibility card",
    deleteAsset: "Delete asset",
    transferAsset: "Transfer asset",
    disposeAsset: "Dispose asset",
    assignmentCreatedSuccessfully: "Assignment created successfully",

    // Success messages
    asset_insert_successful: "The asset has been saved successfully",
    asset_updated_successful: "The asset has been updated successfully",
    asset_deleted_successful: "The asset has been deleted successfully",
    asset_assignment_successful: "The asset has been assigned successfully",
    asset_return_successful: "The asset has been returned successfully",

    // Confirmation messages
    sureDeleteAsset: "Are you sure you want to delete this asset?",
    sureReturnAsset: "Are you sure you want to return this asset?",

    // Search and filter
    searchAsset: "Search asset",
    filterByType: "Filter by type",
    filterByStatus: "Filter by status",
    filterByLocation: "Filter by location",
    filterByDates: "Filter by dates",

    selectDate: "Select date",
    expenseRequest: "Expense request",
    image: "Image",
    noImageSelected: "No image selected",
    editActive: "Edit active",
    depreciationPercentage: "Depreciation percentage",
    returnReasons: "Returns reasons",
    confirm: "Confirm",
    administrativeActives: "Institutional actives",

    // Reports
    assetReports: "Asset reports",
    depreciationReport: "Depreciation report",
    assignmentReport: "Assignment report",
    inventoryReport: "Inventory report",
    exportToExcel: "Export to excel",
    exportToPDF: "Export to pdf"
  },
  es: {
    // General asset management
    assets: "Activos",
    assetsList: "Listado de activos",
    addAsset: "Agregar activo",
    editAsset: "Editar activo",
    assetDetails: "Detalles del activo",

    // Asset properties
    assetName: "Nombre del activo",
    assetCode: "Código del activo",
    assetType: "Tipo de activo",
    productType: "Tipo de producto",
    acquisitionDate: "Fecha de adquisición",
    expiryDate: "Fecha de vencimiento",
    value: "Valor",
    valueProduct: "Valor del producto",
    depreciation: "Depreciación",
    percentDepreciation: "Porcentaje de depreciación",
    observations: "Observaciones",

    // Asset related entities
    productTypes: "Tipos de productos",
    unitMeasurement: "Unidad de medida",
    cooperant: "Cooperante",
    provider: "Proveedor",

    // Asset statuses
    inactive: "Inactivo",
    actives: "Activos",
    activeList: "Lista de activos",
    addActive: "Agregar activo",

    generateQRCode: "Generar código QR",
    assetVerificationQR: "Código QR de verificación",
    assetID: "ID del Activo",
    scanQRToVerify: "Escanea este código QR para verificar el activo",
    downloadQR: "Descargar QR",

    // Asset assignment
    assignAsset: "Asignar activo",
    assignments: "Asignaciones",
    newAssignment: "Nueva asignación",
    deliveryDate: "Fecha de entrega",
    returnDate: "Fecha de devolución",
    returnAsset: "Devolver activo",
    currentAssignee: "Asignado actualmente a",
    assignmentHistory: "Historial de asignaciones",
    noAssignmentsFound: "No se encontraron asignaciones",
    headquarters: "Sede",
    user: "Usuario",

    // Asset operations
    printCard: "Imprimir tarjeta",
    printResponsibilityCard: "Imprimir tarjeta de responsabilidad",
    responsibilityCard: "Tarjeta de responsabilidad",
    deleteAsset: "Eliminar activo",
    transferAsset: "Transferir activo",
    disposeAsset: "Dar de baja",
    assignmentCreatedSuccessfully: "Activo creado exitosamente",

    // Success messages
    asset_insert_successful: "El activo se ha guardado correctamente",
    asset_updated_successful: "El activo se ha actualizado correctamente",
    asset_deleted_successful: "El activo se ha eliminado correctamente",
    asset_assignment_successful: "El activo se ha asignado correctamente",
    asset_return_successful: "El activo ha sido devuelto correctamente",

    // Confirmation messages
    sureDeleteAsset: "¿Está seguro que desea eliminar este activo?",
    sureReturnAsset: "¿Está seguro que desea devolver este activo?",

    // Search and filter
    searchAsset: "Buscar activo",
    filterByType: "Filtrar por tipo",
    filterByStatus: "Filtrar por estado",
    filterByLocation: "Filtrar por ubicación",
    filterByDates: "Filtrar por fechas",

    selectDate: "Seleccionar fecha",
    expenseRequest: "ID de solicitud",
    image: "Imagen",
    noImageSelected: "Ninguna imagen seleccionada",
    editActive: "Editar activo",
    depreciationPercentage: "Porcentaje de depreciación",
    returnReasons: "Razón de devolución",
    confirm: "Confirmar",
    administrativeActives: "Activos institucionales",

    // Reports
    assetReports: "Reportes de activos",
    depreciationReport: "Reporte de depreciación",
    assignmentReport: "Reporte de asignaciones",
    inventoryReport: "Reporte de inventario",
    exportToExcel: "Exportar a excel",
    exportToPDF: "Exportar a pdf"
  }
};

export default assetsLang;