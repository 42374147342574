import { RouteRecordRaw } from "vue-router";

const administrativeRoutes: Array<RouteRecordRaw> = [
  {
    path: "/administrative",
    name: "administrative",
    component: () => import("@/views/administrative/index.vue"),
    meta: { requiresAuth: true, permission: "administrativo" },
    children: [
      {
        path: "activities",
        name: "administrative-activities",
        component: () =>
          import("@/views/administrative/activities/index.vue"),
        meta: { requiresAuth: true, permission: "administrativo_actividades" },
      },
      // actives
      {
        path: "actives",
        name: "administrative-actives",
        redirect: {
          name: "administrative-actives-list",
        },
        component: () => import("@/views/administrative/actives/index.vue"),
        meta: {
          requiresAuth: true,
          permission: "administrativo_activos",
        },
        children: [
          {
            path: "add",
            name: "administrative-actives-add",
            component: () =>
                import("@/views/administrative/actives/formActives.vue"),
            meta: {
              requiresAuth: true,
              permission: "administrativo_activos",
            },
          },
          {
            path: "edit/:id",
            props: true,
            name: "administrative-actives-edit",
            component: () =>
                import("@/views/administrative/actives/formActives.vue"),
            meta: {
              requiresAuth: true,
              permission: "administrativo_activos",
            },
          },
          {
            path: "list",
            name: "administrative-actives-list",
            component: () =>
                import("@/views/administrative/actives/listActives.vue"),
            meta: {
              requiresAuth: true,
              permission: "administrativo_activos",
            },
          },
        ],
      },
    ],
  },
];

export default administrativeRoutes;
