import PermisionService from "@/core/services/user/PermisionService";
import ApiService from "@/core/services/ApiService";
import { isBoolean, isDate, isNumber, isString } from "lodash";

export const hasPermission = (permission: string): boolean => {
  const objPermissions = JSON.parse(PermisionService.getPermissions() || "");
  return Object.values(objPermissions).indexOf(permission) > -1;
};

export const strParams = (obj): string => {
  return Object.keys(obj)
    .map(function (key) {
      return key + "=" + obj[key];
    })
    .join("&");
};
export const dateFormat = (t, a, s): string => {
  function format(m) {
    const f = new Intl.DateTimeFormat("es", m);
    return f.format(t);
  }
  const dateFormatted = a.map(format);
  dateFormatted.forEach((item, key) => {
    if (item.length === 1) {
      dateFormatted[key] = "0" + item;
    }
  });
  return dateFormatted.join(s);
};

/**
 * Format a number as currency with Q symbol and 2 decimal places
 * @param value - Number to format
 * @returns Formatted currency string
 */
export const currencyFormat = (value: number | string | null | undefined): string => {
  if (value === null || value === undefined || value === '') return '-';

  const numValue = typeof value === 'string' ? parseFloat(value) : value;
  if (isNaN(numValue)) return '-';

  return `Q ${numValue.toFixed(2)}`;
};

export const format = [
  { year: "numeric" },
  { month: "numeric" },
  { day: "numeric" },
];

export const formatPublic = [
  { day: "numeric" },
  { month: "numeric" },
  { year: "numeric" },
];

export const dateTimeFormatDB = (date): string => {
  return (
    date.getFullYear().toString() +
    "-" +
    pad2(date.getMonth() + 1) +
    "-" +
    pad2(date.getDate()) +
    " " +
    pad2(date.getHours()) +
    ":" +
    pad2(date.getMinutes()) +
    ":" +
    pad2(date.getSeconds())
  );
};

export const dateTimeFormat = (date): string => {
  return (
    pad2(date.getDate()) +
    "-" +
    pad2(date.getMonth() + 1) +
    "-" +
    date.getFullYear().toString() +
    " " +
    pad2(date.getHours()) +
    ":" +
    pad2(date.getMinutes()) +
    ":" +
    pad2(date.getSeconds())
  );
};

export const timestamp = (date): string => {
  return (
    pad2(date.getDate()) +
    "_" +
    pad2(date.getMonth() + 1) +
    "_" +
    date.getFullYear().toString() +
    "_" +
    pad2(date.getHours()) +
    "_" +
    pad2(date.getMinutes()) +
    "_" +
    pad2(date.getSeconds())
  );
};

export const pad2 = (n): string => {
  return n < 10 ? "0" + n : n;
};

export const copyToClipboard = (str) => {
  const el = document.createElement("textarea");
  el.value = str;
  el.setAttribute("readonly", "");
  el.style.position = "absolute";
  el.style.left = "-9999px";
  document.body.appendChild(el);
  el.select();
  const success = document.execCommand("copy");
  document.body.removeChild(el);
  return success;
};

export const removeCharsString = (str): string => {
  const from = "ÃÀÁÄÂÈÉËÊÌÍÏÎÒÓÖÔÙÚÜÛãàáäâèéëêìíïîòóöôùúüûÑñÇç";
  const to = "AAAAAEEEEIIIIOOOOUUUUaaaaaeeeeiiiioooouuuunncc";
  const mapping = {};

  for (let i = 0, j = from.length; i < j; i++) {
    mapping[from.charAt(i)] = to.charAt(i);
  }

  const ret = Array<string>();
  for (let i = 0, j = str.length; i < j; i++) {
    const c = str.charAt(i);
    if (mapping[c]) ret.push(mapping[c]);
    else ret.push(c);
  }
  return ret.join("");
};

export const getCatalog = async (
  url: string,
  id: string,
  params: object = {}
) => {
  const { data } = await ApiService.postAsync(url, params);
  if (data.success) {
    return data.data.map((row) => {
      return {
        label: `${row.code ?? ""}${row.code ? " - " : ""}${row.cname}`,
        value: row[id],
      };
    });
  }
  return [];
};

export const generateUniqueId = () => {
  const now = new Date();
  const uniquePart = Math.random().toString(36).substring(2, 15); // Genera una cadena aleatoria
  return now.getTime().toString() + uniquePart; // Combina el tiempo actual en milisegundos con la cadena aleatoria
}

export const resetValue = (value) => {
  if (isString(value)) value = "";
  if (isNumber(value)) value = 0;
  if (isBoolean(value)) value = false;
  if (isDate(value)) value = new Date();
  return value;
};

export default {
  hasPermission,
  strParams,
  dateFormat,
  format,
  formatPublic,
  currencyFormat,
  copyToClipboard,
  removeCharsString,
  getCatalog,
  generateUniqueId,
};
