import { createI18n } from "vue-i18n";
import accountLang from "@/langs/account";
import populationLang from "@/langs/population/population";
import headquarterLang from "@/langs/configuration/headquarter";
import configurationLang from "@/langs/configuration/configuration";
import documentationLang from "@/langs/documentation/documentation";
import budgetLang from "@/langs/budget/budget";
import planningLang from "@/langs/budget/planning";
import assignmentLang from "@/langs/budget/assignment";
import poaLang from "@/langs/execution/poa";
import requestLang from "@/langs/execution/request";
import executionLang from "@/langs/execution/execution";
import ticketsLang from "@/langs/widget/tickets";
import cooperatingLang from "@/langs/configuration/cooperating";
import usersLang from "@/langs/configuration/users";
import rolesLang from "@/langs/configuration/roles";
import providersLang from "@/langs/configuration/providers";
import personalLang from "@/langs/execution/personal";
import reportLang from "@/langs/report/report";
import talentLang from "@/langs/talent/talent";
import administrativeLang from "@/langs/administrative/administrative";
import assetsLang from "@/langs/administrative/active";

const messages = {
  en: {
    nameApp: "Angel Ariel",
    dashboard: "Dashboard",
    layoutBuilder: "Layout builder",
    craft: "Crafted",
    pages: "Pages",
    projects: "Projects",
    campaigns: "Campaigns",
    documents: "Documents",
    connections: "Connections",
    wizards: "Wizards",
    horizontal: "Horizontal",
    vertical: "Vertical",
    authentication: "Sign In",
    basicFlow: "Basic Flow",
    signIn: "Sign-in",
    signUp: "Sign-up",
    passwordReset: "Password Reset",
    error404: "Error 404",
    error500: "Error 500",
    apps: "Apps",
    chat: "Chat",
    privateChat: "Private Chat",
    groupChat: "Group Chat",
    drawerChat: "Drawer Chat",
    widgets: "Widgets",
    widgetsLists: "Lists",
    widgetsStatistics: "Statistics",
    widgetsCharts: "Charts",
    widgetsMixed: "Mixed",
    widgetsTables: "Tables",
    widgetsFeeds: "Feeds",
    changelog: "Changelog",
    docsAndComponents: "Docs & Components",
    megaMenu: "Mega Menu",
    exampleLink: "Example link",
    modals: "Modals",
    general: "General",
    inviteFriends: "Invite Friends",
    viewUsers: "View Users",
    upgradePlan: "Upgrade Plan",
    shareAndEarn: "Share & Earn",
    forms: "Forms",
    newTarget: "New Target",
    newCard: "New Card",
    newAddress: "New Address",
    createAPIKey: "Create API Key",
    twoFactorAuth: "Two Factor Auth",
    createApp: "Create App",
    createAccount: "Create Account",
    documentation: "Documentation",
    components: "Components",
    resources: "Resources",
    activity: "Activity",
    customers: "Customers",
    gettingStarted: "Getting Started",
    customersListing: "Customers Listing",
    customerDetails: "Customers Details",
    language: "Language",
    logOut: "Sign out",
    goBack: "Go back",
    username: "Username",
    password: "Password",
    rePassword: "Confirm password",
    forgotPassword: "Forgot password?",
    userRequired: "Username is required field",
    passwordRequired: "Password is required field",
    rePasswordRequired: "Please, confirm your password",
    emailRequired: "Email is a required field",
    legendForgotPassword: "Enter your email to reset your password.",
    legendResetPassword: "Restore password",
    errorEmail: "The password must contain at least 8 characters",
    errorPassword: "The password must contain at least 8 characters",
    email: "Email",
    cancel: "Cancel",
    submit: "Submit",
    ok: "Ok",
    systemMessage: "System message",
    calendarApp: "Calendar",
    forgotMessage: "Please check your email to reset password",
    resetMessage: "Your password has saved succefuly",
    pleaseWait: "Please wait...",
    save: "Save",
    new: "New",
    edit: "Edit",
    list: "List",
    home: "Home",
    active: "Active",
    actions: "Actions",
    delete: "Delete",
    deleted: "Deleted",
    yes: "Yes",
    no: "No",
    emptyElements: "Not found results",
    noConnect: "Could not save data at this time",
    add: "Add",
    pickOfValue: "Pick of value",
    address: "Address",
    addressRequired: "Address is a required field",
    close: "Close",
    location: "Location | Locations",
    search: "Search",
    filter: "Filter | Filters",
    year: "Year | Years",
    months: {
      enero: "January",
      febrero: "February",
      marzo: "March",
      abril: "April",
      mayo: "May",
      junio: "June",
      julio: "July",
      agosto: "August",
      septiembre: "September",
      octubre: "October",
      noviembre: "November",
      diciembre: "December",
    },
    total: "Total",
    completeFields: "Must fill the required fields",
    cantContinue: "The request could not be made, please try again",
    sureUnsaved: "Are you sure you want to continue without saving?",
    clean: "Clean",
    user_not_exist: "User does not match on our system",
    date: "Date",
    description: "Description",
    status: "Status",
    to: "To",
    startDate: "Start date",
    endDate: "End date",
    // Estados de solicitudes
    solicitado: "Requested",
    aprobado_jefe: "Boss approval",
    aprobacion_superior: "Superior approval",
    aprobacion: "Approval",
    cuadro_comparativo_admin: "Administrative comparison chart",
    proveedor_seleccionado: "Selected provider",
    emision_cheque: "Check issuance",
    liquidado_temp: "Temporary liquidation",
    enviado_liquidacion: "Sent for clearance",
    aprobar_liquidacion: "Clearance approved",
    rechazado: "Rejected",
    returnToStatus: "Regresar a estado",
    reasonForRejection: "Reason for rejection",
    // Estados de solicitudes-----FIN
    copySuccess: "Copied!",
    print: "Print",
    technicalSheet: "Technical sheet",
    warning: "Advertencia",
    goContinue: "Are you sure to continue?",
    pickDate: "Pick a date",
    cantCopy: "Data could not be copied",
    id: "ID",
    code: "Code",
    attachments: "Attachments",
    name: "Name",
    fieldRequired: "{0} is a required field",
    numberRequired: "{0} must be a number",
    fieldInvalid: "{0} is invalid",
    selectOne: "Please select at least one {0}",
    pngJpgAvailable: "The {0} must be png or jpg",
    sizeAvailable: "The {0} cannot exceed {1}",
    onlyMailOrganization: "The mail must be institutional",
    selectAll: "Select all",
    today: "Today",
    yesterday: "Yesterday",
    aWeekAgo: "A week ago",
    aMonthAgo: "A month ago",
    twoMonthsAgo: "Two months ago",
    dateOfBirth: "Date of birth",
    dpi: "DPI",
    identificationDocument: "identification document",
    update: "Update",
    male: "Male",
    female: "Female",
    loading: "Loading...",
    uploadFile: "Upload file",
    uploadFileError: "Upload file error",
    enterFileName: "Enter file name",
    pressEnterToSearch: "Press Enter to search",
    cannotReadFile: "Can not read file",
    ...accountLang.en,
    ...populationLang.en,
    ...configurationLang.en,
    ...documentationLang.en,
    ...headquarterLang.en,
    ...budgetLang.en,
    ...planningLang.en,
    ...assignmentLang.en,
    ...executionLang.en,
    ...poaLang.en,
    ...requestLang.en,
    ...ticketsLang.en,
    ...cooperatingLang.en,
    ...usersLang.en,
    ...rolesLang.en,
    ...providersLang.en,
    ...personalLang.en,
    ...reportLang.en,
    ...talentLang.en,
    ...administrativeLang.en,
    ...assetsLang.en,
  },
  es: {
    nameApp: "Angel Ariel",
    dashboard: "Tablero",
    layoutBuilder: "Constructor de maquetación",
    craft: "Elaborado",
    pages: "Paginas",
    projects: "Proyectos",
    campaigns: "Campañas",
    documents: "Documentos",
    connections: "Conexiones",
    wizards: "Magos",
    horizontal: "Horizontal",
    vertical: "Vertical",
    authentication: "Iniciar sesión",
    basicFlow: "Flujo básico",
    signIn: "Ingresar",
    signUp: "Inscribirse",
    passwordReset: "Restablecimiento de contraseña",
    error404: "Error 404",
    error500: "Error 500",
    apps: "Aplicaciones",
    chat: "Chat",
    privateChat: "Chat privado",
    groupChat: "Grupo de chat",
    drawerChat: "Chat del cajón",
    widgets: "Widgets",
    widgetsLists: "Liza",
    widgetsStatistics: "Estadísticas",
    widgetsCharts: "Gráficos",
    widgetsMixed: "Mezclada",
    widgetsTables: "Mesas",
    widgetsFeeds: "Alimenta",
    changelog: "Registro de cambios",
    docsAndComponents: "Documentos & Componentes",
    megaMenu: "Mega menú",
    exampleLink: "Enlace de ejemplo",
    modals: "Modales",
    general: "General",
    inviteFriends: "Invitar A Amigos",
    viewUsers: "Ver Usuarios",
    upgradePlan: "Plan De Actualización",
    shareAndEarn: "Compartir Y Ganar",
    forms: "Formas",
    newTarget: "Nuevo Objetivo",
    newCard: "Nueva Tarjeta",
    newAddress: "Nueva Dirección",
    createAPIKey: "Crea Clave De Api",
    twoFactorAuth: "Dos Factores",
    createApp: "Crear Aplicación",
    createAccount: "Crear Una Cuenta",
    activity: "Actividad",
    documentation: "Documentación",
    components: "Componentes",
    resources: "Recursos",
    customers: "Clientes",
    gettingStarted: "Empezando",
    customersListing: "Listado De Clientes",
    customerDetails: "Detalles De Los Clientes",
    language: "Idioma",
    logOut: "Cerrar sesión",
    goBack: "Regresar",
    username: "Usuario",
    password: "Contraseña",
    rePassword: "Confirmar contraseña",
    forgotPassword: "¿Has olvidado tu contraseña?",
    userRequired: "Usuario es un campo requerido",
    passwordRequired: "Contraseña es un campo requerido",
    rePasswordRequired: "Confirmar contraseña es un campo requerido",
    emailRequired: "Correo electrónico es un campo requerido",
    legendForgotPassword: "Ingrese su correo para reiniciar su contraseña.",
    legendResetPassword: "Restablecer contraseña",
    errorEmail: "Debe ser un correo electrónico valido",
    errorPassword: "La contraseña debe contener al menos 8 caracteres",
    email: "Correo electrónico",
    cancel: "Cancelar",
    submit: "Enviar",
    ok: "Aceptar",
    systemMessage: "Mensaje del sistema",
    calendarApp: "Calendario",
    forgotMessage:
      "Por favor revisar su correo electrónico para restablecer contraseña",
    resetMessage: "Su contraseña se ha guardado exitosamente",
    pleaseWait: "Cargando...",
    save: "Guardar",
    new: "Nuevo",
    edit: "Editar",
    list: "Listado",
    home: "Inicio",
    active: "Activo",
    actions: "Acciones",
    delete: "Eliminar",
    deleted: "Eliminado",
    yes: "Si",
    no: "No",
    emptyElements: "No se encuentran resultados",
    noConnect: "No se pudieron guardar los datos en este momento",
    add: "Agregar",
    pickOfValue: "Seleccione una opción",
    address: "Dirección",
    addressRequired: "Dirección es un campo requerido",
    close: "Cerrar",
    location: "Ubicación | Ubicaciones",
    search: "Buscar",
    filter: "Filtro | Filtros",
    year: "Año | Años",
    months: {
      enero: "Enero",
      febrero: "Febrero",
      marzo: "Marzo",
      abril: "Abril",
      mayo: "Mayo",
      junio: "Junio",
      julio: "Julio",
      agosto: "Agosto",
      septiembre: "Septiembre",
      octubre: "Octubre",
      noviembre: "Noviembre",
      diciembre: "Diciembre",
    },
    total: "Total",
    completeFields: "Debe completar los campos requeridos",
    cantContinue: "No se pudo realizar la petición, por favor intente de nuevo",
    sureUnsaved: "¿Seguro que desea continuar sin guardar?",
    clean: "Limpiar",
    user_not_exist: "El usuario no se encuentra en nuestro sistema",
    date: "Fecha",
    description: "Descripción",
    status: "Estado",
    to: "A",
    startDate: "Fecha inicio",
    endDate: "Fecha final",
    // Estados de solicitudes
    solicitado: "Solicitado",
    aprobado_jefe: "Aprobación jefe",
    aprobacion_superior: "Aprobación superior",
    aprobacion: "Aprobación",
    cuadro_comparativo_admin: "Cuadro comparativo administrativo",
    proveedor_seleccionado: "Proveedor seleccionado",
    emision_cheque: "Emisión de cheque",
    liquidado_temp: "Liquidación temporal",
    enviado_liquidacion: "Enviado a liquidación",
    aprobar_liquidacion: "Liquidación aprobada",
    rechazado: "Rechazado",
    returnToStatus: "Regresar a estado",
    reasonForRejection: "Motivo de rechazo",
    // Estados de solicitudes-----FIN
    copySuccess: "Copiado!",
    print: "Imprimir",
    technicalSheet: "Hoja técnica",
    warning: "Advertencia",
    goContinue: "¿Seguro que desea continuar?",
    pickDate: "Seleccione una fecha",
    cantCopy: "No se pudo copiar la información",
    id: "ID",
    code: "Código",
    attachments: "Adjuntos",
    name: "Nombre",
    fieldRequired: "{0} es un campo requerido",
    numberRequired: "{0} debe de ser un número",
    fieldInvalid: "{0} es inválido",
    selectOne: "Por favor seleccione al menos uno de {0}",
    pngJpgAvailable: "La {0} debe ser png o jpg",
    sizeAvailable: "La {0} no puede exceder {1}",
    onlyMailOrganization: "El correo debe ser institucional",
    selectAll: "Seleccionar todos",
    today: "Hoy",
    yesterday: "Ayer",
    aWeekAgo: "Una semana",
    aMonthAgo: "Un mes",
    twoMonthsAgo: "Dos meses",
    dateOfBirth: "Fecha de nacimiento",
    dpi: "DPI",
    identificationDocument: "Documento de identificación",
    update: "Actualizar",
    male: "Masculino",
    female: "Femenino",
    loading: "Cargando...",
    uploadFile: "Subir archivo",
    uploadFileError: "Error al subir archivo",
    enterFileName: "Ingrese nombre del archivo",
    pressEnterToSearch: "Presione Enter para buscar",
    cannotReadFile: "No se puede leer el archivo",
    ...accountLang.es,
    ...populationLang.es,
    ...configurationLang.es,
    ...documentationLang.es,
    ...headquarterLang.es,
    ...budgetLang.es,
    ...planningLang.es,
    ...assignmentLang.es,
    ...executionLang.es,
    ...poaLang.es,
    ...requestLang.es,
    ...ticketsLang.es,
    ...cooperatingLang.es,
    ...usersLang.es,
    ...rolesLang.es,
    ...providersLang.es,
    ...personalLang.es,
    ...reportLang.es,
    ...talentLang.es,
    ...administrativeLang.es,
    ...assetsLang.es,
  },
};

const i18n = createI18n({
  legacy: false,
  locale: "es",
  globalInjection: true,
  messages,
});

export default i18n;
